/* eslint-disable no-restricted-syntax */

/**
 * Computes the URL of a floatingbox integration - to be used in an iframe
 *
 * @param {String} appUrl The platform url (ie. citi.career-inspiration.com)
 * @param {String} initalPath The page where the app should open
 * @param {String} land The land ID
 * @param {boolean} enableDebug Should the app load in debug mode
 * @param {Location} refererLocation The referer calling the appbox
 * @returns {String} A floatingbox URL
 */
export function getFloatingboxUrl({ appUrl, land, initialPath, enableDebug, refererLocation }) {
  const query = {
    go: null,
    utm_medium: 'floatingbox',
    utm_campaign: 'plugin',
    utm_source: refererLocation.hostname,
    r: refererLocation.href,
  };

  if (enableDebug) {
    query.pmDebug = '';
  }

  if (initialPath) {
    query.app_data = initialPath;
  }

  const floatingUrl = new URL(`https://${appUrl}`);
  floatingUrl.pathname = ['/app/floatingbox/', land ? `landed:${land}/` : ''].join('');

  for (const [key, value] of Object.entries(query)) {
    floatingUrl.searchParams.set(key, value);
  }

  return floatingUrl.toString();
}
/**
 * Computes the URL of an appbox integration - to be used in an iframe
 *
 * @param {String} appUrl The platform url (ie. citi.career-inspiration.com)
 * @param {String} initalPath The page where the app should open
 * @param {String} land The land ID
 * @param {boolean} enableDebug Should the app load in debug mode
 * @param {Location} refererLocation The referer calling the appbox
 * @returns {String} An appbox URL
 */
export function getAppboxUrl({ appUrl, land, initialPath, enableDebug, refererLocation }) {
  const query = {
    go: null,
    utm_medium: 'appbox',
    utm_campaign: 'plugin',
    utm_source: refererLocation.hostname,
    r: refererLocation.href,
  };

  if (enableDebug) {
    query.pmDebug = '';
  }

  if (initialPath) {
    query.app_data = initialPath;
  }

  const appboxUrl = new URL(`https://${appUrl}`);
  appboxUrl.pathname = ['/app/appbox/', land ? `landed:${land}/` : ''].join('');

  for (const [key, value] of Object.entries(query)) {
    appboxUrl.searchParams.set(key, value);
  }

  return appboxUrl.toString();
}

/**
 * Computes the URL of an chatbox integration - to be used in an iframe
 *
 * @param {String} appUrl The platform url (ie. citi.career-inspiration.com)
 * @param {String} land The land ID
 * @param {String} chat The chat ID
 * @param {String} title The title of the box
 * @param {boolean} enableDebug Should the app load in debug mode
 * @param {Location} refererLocation The referer calling the appbox
 * @returns {String} A chatbox URL
 */
export function getChatboxUrl({ appUrl, land, chat, title, enableDebug, refererLocation }) {
  const query = {
    app: appUrl,
    utm_medium: 'chatbox',
    utm_campaign: 'plugin',
    utm_source: refererLocation.hostname,
    r: refererLocation.href,
  };

  if (enableDebug) {
    query.pmDebug = '';
  }

  if (chat) {
    query.chat = chat;
  }

  if (title) {
    query.title = title;
  }

  const chatboxUrl = new URL(`https://${appUrl}`);
  chatboxUrl.pathname = ['/app/chatbox/', land ? `landed:${land}/` : ''].join('');

  for (const [key, value] of Object.entries(query)) {
    chatboxUrl.searchParams.set(key, value);
  }

  return chatboxUrl.toString();
}

/**
 * Computes the URL of a promobox integration - to be used in an iframe
 *
 * @param {String} appUrl The platform url (ie. citi.career-inspiration.com)
 * @param {String} land The land ID
 * @param {String} title box title
 * @param {Integer|false} nbCommentsShown Number of comments to show
 * @param {Integer|false} nbInsidersShown Number of insiders to show
 * @param {Integer|false} nbCategoriesShown Number of categories to show
 * @param {Integer[]} insidersIdsToShow The ids of the insiders profiles to promote
 * @param {Integer[]} categoriesIdsToShow The ids of the categories of profiles to promote
 * @param {boolean} enableDebug Should the app load in debug mode
 * @param {Location} refererLocation The referer calling the appbox
 * @param {boolean} promoteLivechat Show or not Live Chat promo box
 * @param {boolean} allowAnonymousDiscussionsAndRepliesAndReplies Whether to allow anonymous discussions
 * @returns {String} A promobox URL
 */
export function getPromoboxUrl({
  appUrl,
  land,
  title,
  nbCommentsShown,
  nbInsidersShown,
  insidersIdsToShow,
  categoriesIdsToShow,
  enableDebug,
  refererLocation,
  promoteLivechat,
  allowAnonymousDiscussionsAndReplies,
}) {
  const query = {
    utm_medium: 'pmpromobox',
    utm_campaign: 'plugin',
    utm_source: refererLocation.hostname,
    r: refererLocation.href,
    com: nbCommentsShown || false,
    app: appUrl,
    promote_livechat: promoteLivechat,
    allow_anonymous_discussions_and_replies: allowAnonymousDiscussionsAndReplies,
  };

  const promoboxUrl = new URL(`https://${appUrl}`);

  if (insidersIdsToShow) {
    for (const id of insidersIdsToShow) {
      promoboxUrl.searchParams.append('insiders[]', id);
    }
  } else if (nbInsidersShown) {
    query.insiders = nbInsidersShown;
  }

  if (categoriesIdsToShow) {
    for (const id of categoriesIdsToShow) {
      promoboxUrl.searchParams.append('categories[]', id);
    }
  }

  if (enableDebug) {
    query.pmDebug = '';
  }

  // in some cases we have string 'null' there.
  if (title && title !== 'null') {
    query.title = title;
  }

  promoboxUrl.pathname = ['/app/promobox/', land ? `landed:${land}/` : ''].join('');

  for (const [key, value] of Object.entries(query)) {
    promoboxUrl.searchParams.set(key, value);
  }

  return promoboxUrl.toString();
}

/**
 * Computes the URL of a discussionsbox integration - to be used in an iframe
 * @param {String} appUrl
 * @param {String} land
 * @param {String} title
 * @param {String} search
 * @param {Number} nbDiscussionsShown
 * @param {boolean} enableDebug
 * @param {String} refererLocation
 * @param {String} containerId
 * @param {Array} greatTopics
 * @param {Array} pmsParentCategories
 * @param {Array} topics
 * @param {boolean} unlimitedDiscussions
 * @returns {String} The discussionsbox url
 */
export function getDiscussionsboxUrl({
  appUrl,
  land,
  title,
  search,
  nbDiscussionsShown,
  enableDebug,
  refererLocation,
  greatTopics,
  pmsParentCategories,
  topics,
  unlimitedDiscussions,
}) {
  const query = {
    utm_medium: 'pmdiscussionsbox',
    utm_campaign: 'plugin',
    utm_source: refererLocation.hostname,
    r: refererLocation.href,
    app: appUrl,
  };

  const discussionsBoxUrl = new URL(`https://${appUrl}`);

  if (enableDebug) {
    query.pmDebug = '';
  }

  // in some cases we have string 'null' there.
  if (title && title !== 'null') {
    query.title = title;
  }

  if (search && search !== 'null') {
    discussionsBoxUrl.searchParams.set('search', search);
  }

  if (greatTopics) {
    for (const id of greatTopics) {
      discussionsBoxUrl.searchParams.append('great_topics[]', id);
    }
  }

  if (pmsParentCategories) {
    for (const id of pmsParentCategories) {
      discussionsBoxUrl.searchParams.append('pms_parent_categories[]', id);
    }
  }

  if (topics) {
    for (const id of topics) {
      discussionsBoxUrl.searchParams.append('topics[]', id);
    }
  }

  if (unlimitedDiscussions) {
    query.unlimited_discussions = unlimitedDiscussions;
  }

  if (nbDiscussionsShown) {
    query.nb_discussions = nbDiscussionsShown;
  }

  discussionsBoxUrl.pathname = ['/app/discussionsbox/', land ? `landed:${land}/` : ''].join('');

  for (const [key, value] of Object.entries(query)) {
    discussionsBoxUrl.searchParams.set(key, value);
  }

  return discussionsBoxUrl.toString();
}

/**
 * Computes the URL of a promobox integration - to be used in an iframe
 *
 * @param {String} appUrl The platform url (ie. citi.career-inspiration.com)
 * @param {String} land The land ID
 * @param {String} title box title
 * @param {String} autoplay switcher for automatic play
 * @param {String} videotarget where video should open (in popup / on page)
 * @param {boolean} enableDebug Should the app load in debug mode
 * @param {Location} refererLocation The referer calling the appbox
 * @param {Integer|Array} videos Video id
 * @param {String} containerId container id
 * @param {boolean} promoteLivechat Show or not Live Chat promo box
 * @returns {String} A videobox URL
 */
export function getVideoboxUrl({
  appUrl,
  land,
  title,
  autoplay,
  videotarget,
  videos,
  enableDebug,
  refererLocation,
  containerId,
  promoteLivechat,
}) {
  const query = {
    utm_medium: 'pmvideobox',
    utm_campaign: 'plugin',
    utm_source: refererLocation.hostname,
    r: refererLocation.href,
    container_id: containerId,
    app: appUrl,
    autoplay,
    videotarget,
    promote_livechat: promoteLivechat,
  };

  if (enableDebug) {
    query.pmDebug = '';
  }

  query['videos[]'] = videos;

  // in some cases we have string 'null' there.
  if (title && title !== 'null') {
    query.title = title;
  }

  const videoBoxUrl = new URL(`https://${appUrl}`);
  videoBoxUrl.pathname = ['/app/videobox/', land ? `landed:${land}/` : ''].join('');

  for (const [key, value] of Object.entries(query)) {
    videoBoxUrl.searchParams.set(key, value);
  }

  return videoBoxUrl.toString();
}

/**
 * Computes the URL of an engagementbox integration - to be used in an iframe
 *
 * @param {String} appUrl The platform url (ie. citi.career-inspiration.com)
 * @param {String} land The land ID
 * @param {String} loggedUser The ID of the user supposed to be logged
 * @param {boolean} forceShowing Get rid of the conditions and force the box to show
 * @param {String} title Title of the box
 * @param {boolean} enableDebug Should the app load in debug mode
 * @param {Location} refererLocation The referer calling the appbox
 * @returns {String} An engagement box URL
 */
export function getEngagementboxUrl({
  appUrl,
  land,
  loggedUser,
  forceShowing,
  title,
  enableDebug,
  refererLocation,
}) {
  const query = {
    utm_medium: 'engagementbox',
    utm_campaign: 'plugin',
    utm_source: refererLocation.hostname,
    r: refererLocation.href,
    app: appUrl,
  };

  if (enableDebug) {
    query.pmDebug = '';
  }

  if (forceShowing) {
    query.forceRendering = forceShowing;
    query.render = forceShowing;
  }

  if (loggedUser) {
    query.user = loggedUser;
  }

  if (title) {
    query.title = title;
  }

  const engagementBoxUrl = new URL(`https://${appUrl}`);
  engagementBoxUrl.pathname = ['/app/engagementbox/', land ? `landed:${land}/` : ''].join('');

  for (const [key, value] of Object.entries(query)) {
    engagementBoxUrl.searchParams.set(key, value);
  }

  return engagementBoxUrl.toString();
}
